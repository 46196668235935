:root {
    --es-dark-green: #006774;
    --es-teal: #00CBBA;
    --es-dark-purple: #650BA5;
    --es-dark-red: #b1325d;
    --es-light-purple: #A475FF;
    --es-lavender: #794dff;
    --es-lavendar-active: #673DE7;
    --es-purple: #543FD3;
    --es-green: #96DF63;
    --es-green-2: #98E163;
    --es-orange: #F8971D;
    --es-dark-blue: #00518E;
    --es-secondary-blue: #3684E6;
    --es-warning-yellow: #FFCC01;
    --es-warning-yellow-bg: #FFF9E2;
    --es-error: #B1325D;
    --es-error-background: #FFF6F9;
    --es-success: #206904;
    --es-success-text: #00864E;
    --es-success-background: #EDFFE7;
    --es-danger-red: #B83728;
    --es-danger-red-bg: #FFE8E6;
    --es-negative-green: #4D8500;
    --es-gray-blue: #F1ECFF;
    --es-info: #E7F6FF;
    --es-navy: #001963;

    --exas-primary: #00518E;
    --exas-secondary: #fff;

    --es-black: #000000;

    --es-nav-black: #101010;
    --es-near-black: #1D2125;
    --es-nav-grey: #2E343A;

    --es-gray-1500: #343434;
    --es-gray-1000: #8D8D8D;
    --es-gray-800: #A7A7A7;
    --es-gray-600: #C0C0C0;
    --es-gray-400: #DADADA;
    --es-gray-200: #F3F3F3;

    --es-background: #FAFAFA;

    --es-white: #FFF;

    --es-breast-cancer: #ED8B00;
    --es-colon-cancer: #005596;
    --es-prostate-cancer: #662566;

    --es-box-shadow: #0000000F;

    /* Inputs */
    --es-button-primary-active: #6034E6;
    --es-button-primary-outline-active: #F8F6FF;
    --es-radio-checked-blue: #044BF0;
    --es-radio-border-grey: #636F83;
}

body {
    font-size: 16px !important;
}

body, textarea {
    font-family: 'Montserrat' , 'San Francisco' , 'Helvetica' , 'Arial', sans-serif !important;
}

/* Buttons, all buttons */
.ui-btn, .ui-btn:visited {
    background-color: var(--es-lavender) !important;
    border-color: var(--es-lavender) !important;
    color: var(--es-white) !important;
    font-weight: 700 !important;
    letter-spacing: 1px !important;
    line-height: 1.25rem !important;
    border-width: 2px !important;
    border-radius: 10px !important;
}
.ui-btn:hover, .ui-btn:active {
    background-color: var(--es-button-primary-active) !important;
}

/* Chat Header, always present */
.ui-header {
    background-color: var(--es-nav-grey) !important;
    height: 1.625rem;
}

.ui-header .ui-title {
    font-size: 0.875rem !important;
    color: var(--es-white) !important;
    font-weight: 500 !important;
    padding: 0 !important;
    padding-top: 0.3rem !important;
}

#header-left-button-container {
    display: none !important;
}

/* iframe */
/* No agents available; after hours */ 
div.chat-sad-image {
    display: none !important;
}

#error-message {
    font-size: 0.875rem !important;
    font-weight: 500 !important;
}

#error-page > .ui-content {
    padding: 0 1em 1em 1em !important;
}

/* Question form preceeding chat */
.form-control > label, .validation-message {
    font-weight: 700 !important;
    line-height: 1.5 !important;
    font-size: 1rem !important;
}

.validation-message {
    color: var(--es-error);
}

.form-control:focus-within {
    color: var(--es-purple);
}

input, textarea {
    font-size: 1.125rem !important;
}

input {
    height: 3.75rem !important;
}

.ui-input-text {
    border: 0;
    font-weight: 500 !important;
    font-size: 1.125rem !important;
    color: var(--es-black);

    & > input:focus, &.ui-focus > input {
        border: 2px solid var(--es-purple) !important;
        caret-color: var(--es-purple) !important;
    }
}

.ui-input-text > input, .form-control > textarea, .input-message-div > .chat-input-message {
    border: 1px solid var(--es-gray-800) !important;
}

.ui-input-text.validation-error > input, .form-control > textarea.validation-error {
    border: 2px solid var(--es-error) !important;
    box-shadow: none !important;

    &:focus {
        border: 2px solid var(--es-purple) !important;
        box-shadow: none !important;
    }
}

.validation-error:focus, .ui-input-text.validation-error.ui-focus {
    box-shadow: none !important;
}

textarea.ui-input-text {
    &:focus {
        border: 2px solid var(--es-purple) !important;
        caret-color: var(--es-purple) !important;
    }
}

/* Messages section */
.chat-content.ui-content {
    bottom: 141px !important;
    top: 28px;
}

.message {
    font-weight: 500 !important;
}

div.inner-container .origin, div.inner-container .display-time {
    font-size: 0.75rem !important;
}

.message-container-agent .message-container {
    color: var(--es-black);
    background-color: var(--es-gray-200);
}

.message-container-client .message-container{
    color: var(--es-white);
    background-color: var(--es-lavender);
}

#conversation-page > .ui-header > .ui-title, #connecting-page > .ui-header > .ui-title {
    text-align: left !important;
    padding-left: 0.5rem !important;
}

#conversation-page #send-button, #conversation-page #send-button:visited {
    background-color: var(--es-lavender) !important;
    width: 100% !important;
    height: 2.25rem !important;
    font-size: 1.125rem !important;
    font-weight: 700 !important;
    margin-top: 1rem !important;
    border-radius: 6px !important;
}
#conversation-page #send-button:hover, #conversation-page #send-button:active {
    background-color: var(--es-button-primary-active) !important;
}

#terminate-conversation-button, #close-button, #terminate-connecting-button {
    color: var(--es-white) !important;
    font-size: 1rem !important;
    background-color: var(--es-nav-grey) !important;
    font-weight: 500 !important;
    border-radius: 0 !important;
    border: none !important;
    padding: 0.1rem 0.5rem 0 0 !important;
}

#conversation-page .ui-footer {
    background-color: var(--es-gray-200) !important;
}

.input-message-div {
    display: block !important;
}

.chat-input-message {
    height: 62px !important;
}

#conversation-page .ui-footer {
    height: 141px !important;
}

.typing {
    font-size: 0.875rem !important;
    font-weight: 500 !important;
}

/* End Chat prompt */
.ui-popup {
    background-color: var(--es-white);
    color: var(--es-black);
}

.ui-popup-content {
    font-size: 0.875rem !important;
    font-weight: 500 !important;
    display: flex !important;
    flex-direction: column !important;
}

.ui-popup-content div {
    display: flex !important;
    flex-direction: column-reverse !important;
}

.terminate-chat-popup .terminate-chat-cancel-button, .terminate-chat-popup .terminate-chat-confirm-button {
    text-decoration: none !important;
    width: 100% !important;
    margin-left: 0 !important;
}

.terminate-chat-popup .terminate-chat-cancel-button, .terminate-chat-popup .terminate-chat-cancel-button:visited {
    background-color: transparent !important;
    color: var(--es-lavender) !important;
    border: 2px solid var(--es-lavender) !important;
}

.terminate-chat-popup .terminate-chat-cancel-button:hover, .terminate-chat-popup .terminate-chat-cancel-button:active {
    color: var(--es-button-primary-active) !important;
    background-color: var(--es-button-primary-outline-active) !important;
    border-color: var(--es-lavender) !important;
}

/* Conversation ended */
.end-who {
    font-weight: 500 !important;
}

.thank-you a, .thank-you a:visited {
    font-weight: 500 !important;
    color: var(--es-lavender) !important;
}

.thank-you a:hover, .thank-you a:active {
    color: var(--es-button-primary-active) !important;
}

/* Loading image */
div.loading-image {
    background-image: url('images/dancing-people.gif') !important;
    background-size: 300px 200px !important;
}

a.provider {
    display: none !important;
    visibility: hidden !important;
    opacity: 0 !important;
}

#question-form-control:after {
    content: "By using this chat feature you consent that Exact Sciences and its chat technology provider can record this chat. This is in accordance with Exact Sciences’ Privacy Policy and Terms of Use.";
    color: #343434;
    font-family: Montserrat, Arial, Helvetica, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px !important;
}

.start-over-button {
    display: none !important;
}
